<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-row *ngIf="isLoading === true">
  <ion-spinner class="safe-area-lr-ionic-margin" name="dots"></ion-spinner>
</ion-row>
<ng-container *ngIf="isLoading === false && randomImportantNews$ | async as randomImportantNews">
  <ion-grid class="border ion-no-padding">
    <ion-row [ngStyle]="{'background-color': randomImportantNews.color}">
      <ion-col>
        <ion-row class="importantNews">
          <ion-col *ngIf="randomImportantNews.image">
            <ion-img [src]="cmsPublicAssetsEndpoint + randomImportantNews.image"></ion-img>
          </ion-col>
          <ion-col class="border-bottom news-content" [size]="randomImportantNews.image ? '6' : '12'"
          [ngClass]="{'without-image': !randomImportantNews.image}">
            <ion-label class="ion-text-wrap text-title" >
              <h2 [ngClass]="fontColor(randomImportantNews.color)" class="app-title-5">{{ randomImportantNews.title }}</h2>
            </ion-label>
            <ion-label class="ion-text-wrap text-content">
              <h3 [ngClass]="fontColor(randomImportantNews.color)" class="app-text-4">{{randomImportantNews.content}}</h3>
            </ion-label>
            <ion-row *ngIf="randomImportantNews.link" class="ion-justify-content-end">
              <ion-button
                (click)="onClick(randomImportantNews)"
                class="app-text-button-1 link-button"
                fill="clear"
              >
                <ion-text [ngClass]="fontColor(randomImportantNews.color)" class="app-text-5"> {{ randomImportantNews.buttonLabel }} </ion-text>
                <ion-icon [ngClass]="fontColor(randomImportantNews.color)" class="app-icon-6 link-icon" name="arrow-right" aria-label=""></ion-icon>
              </ion-button>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>
