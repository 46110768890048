<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-row *ngIf="isLoading">
  <ion-spinner class="ion-margin safe-area-lr-ionic-margin" name="dots"></ion-spinner>
</ion-row>
<ng-container *ngIf='!isLoading'>
  <ng-container *ngIf='(nextEvents$ | async) as events'>
    <ion-row *ngIf='events.length === 0' class="ion-padding">
      <ion-text [ngClass]="fontColor()" class='app-text-4 safe-area-padding-lr'>{{'CALENDAR.NO_EVENTS' | translate}}</ion-text>
    </ion-row>
    <ion-col [ngClass]="fontColor()" *ngFor='let event of events let i = index' class='events-container'>
      <ion-row class='event'>
        <ion-col class='event-part event-part-date safe-area-padding-lr'>
          <ion-text [ngClass]="fontColor()" class='app-text-1'>{{event.startDateTime | localDate}}</ion-text>
          <ion-text [ngClass]="fontColor()" class='app-text-6'>{{event.startDateTime | localTime}}-{{event.endDateTime | localTime}}</ion-text>
        </ion-col>
        <ion-col class='event-part event-part-info safe-area-padding-lr'>
          <ion-text [ngClass]="fontColor()" class='app-text-4 strong'>{{event.label}}</ion-text>
          <ion-text [ngClass]="fontColor()" class='app-text-4'>{{event.location}}</ion-text>
        </ion-col>
      </ion-row>
      <hr  *ngIf='i !== events.length - 1'>
    </ion-col>
  </ng-container>
</ng-container>
