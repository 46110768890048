<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<app-header></app-header>
<ion-content>
  <ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>

  <ion-list>
    <ion-list *ngIf="(restaurants$ | async) as restaurants">
      <ion-item *ngFor="let restaurant of restaurants" (click)="navigateToRestaurantMenus(restaurant.id)">
        <ion-grid>
          <ion-row>
            <ion-col size="3">
              <div class="border"></div>

              <ion-img [src]="restaurant.thumbnailUrl"></ion-img>
              <ion-icon (click)="unsetFavoriteRestaurant(restaurant.id)" *ngIf="restaurant.favorite"
                        [attr.aria-label]="'RESTAURANTS.REMOVE_FROM_FAVORITES' | translate: { name: restaurant.title }"
                        class="app-icon-4 favorite-icon" name="heart">
              </ion-icon>
              <ion-icon (click)="setFavoriteRestaurant(restaurant.id)" *ngIf="!restaurant.favorite"
                        [attr.aria-label]="'RESTAURANTS.ADD_TO_FAVORITES' | translate: { name: restaurant.title }"
                        class="app-icon-4 favorite-icon" name="heart-outline">
              </ion-icon>
            </ion-col>
            <ion-col size="6" class="ion-text-start">
              <ion-label>
                <h2 class="app-title-6 title">{{ restaurant.title }}</h2>
              </ion-label>
              <ion-label class="ion-text-wrap description" lines="2">
                <p class="app-text-4">{{ restaurant.shortDesc }}</p>
              </ion-label>
            </ion-col>
            <ion-col size="3" class="ion-text-end" *ngIf="restaurant.distance">
              <ion-label>
                <h2 class="app-title-7">{{ restaurant.distance | number : '1.1-1' }} {{ "RESTAURANTS.KILOMETERS" |
                  translate }} </h2>
              </ion-label>
              <ion-icon size="large" name="pin-outline" class="pin" aria-label=""></ion-icon>
            </ion-col>
          </ion-row>
          <ion-label *ngIf="getCurrentOpening(restaurant.opening)?.isOpen" class="opening">
            <h2 class="app-text-6">
              {{ getCurrentOpening(restaurant.opening).label }}
            </h2>
          </ion-label>
          <ion-label *ngIf="!getCurrentOpening(restaurant.opening)?.isOpen" class="opening">
            <h2 class="app-text-6 danger">
              {{ 'RESTAURANTS.CLOSED' | translate }}
            </h2>
          </ion-label>
        </ion-grid>
      </ion-item>
    </ion-list>
  </ion-list>

  <ng-container *ngIf="(restaurantsIsEmpty$ | async) && !isLoading">
    <ion-row class="ion-margin ion-justify-content-center">
      <ion-text>{{ "RESTAURANTS.NO_DATA" | translate }}</ion-text>
    </ion-row>
  </ng-container>

</ion-content>
