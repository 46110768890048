<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<svg
	xmlns="http://www.w3.org/2000/svg"
	height="100%"
	width="100%"
	viewBox="0 0 850 540"
	preserveAspectRatio="xMidYMin meet"
	class="svg-card"
>

	<!-- Card white background -->
	<rect rx="10" ry="10" width="850" height="540" fill="white" />

	<!-- Student photo -->
	<image height="320" width="240" x="605" y="5" [attr.href]="userAndCardsData.photo"/>

	<!-- Lastname -->
	<text id="lastname" x="40" y="70" font-family="Helvetica" font-size="33" letter-spacing="-0.5" font-weight="600">
		{{userAndCardsData.lastname}}
	</text>

	<!-- Firstname -->
	<text x="40" y="107" font-family="Helvetica" font-size="33" letter-spacing="-0.5" font-weight="600">
		{{userAndCardsData.firstname}}
	</text>

	<!-- Apogee ID -->
	<text x="590" y="120" font-family="Helvetica" font-size="25" letter-spacing="1" font-weight="200" text-anchor="end">
		{{userAndCardsData.cards.studentCard.idNumber}}
	</text>

	<!-- INE Number -->
	<text x="590" y="150" font-family="Helvetica" font-size="25" letter-spacing="1" font-weight="200" text-anchor="end">
		{{userAndCardsData.ine}}
	</text>

	<!-- Card Title -->
	<text
		x="40"
		y="270"
		font-family="Helvetica"
		font-size="33"
		letter-spacing="0"
		font-weight="600"
		font-style="italic"
	>
	    {{userAndCardsData.cards.studentCard.title | uppercase}}
	</text>

	<!-- Title Complement -->
	<text
		x="40"
		y="312"
		font-family="Helvetica"
		font-size="33"
		letter-spacing="0"
		font-weight="600"
		font-style="italic"
	></text>

	<!-- Logo UL -->
	<image height="60" x="40" y="450" href="assets/cards/university-logo.svg" />

	<!-- Millesime y -->
	<text
		x="650"
		y="420"
		font-family="Helvetica"
		font-size="35"
		letter-spacing="1"
		font-weight="600"
		font-style="italic"
		fill="gray"
	>
	{{userAndCardsData.cards.studentCard.endDate - 1}}

	</text>

	<!-- Millesime y + 1 -->
	<text
		x="710"
		y="455"
		font-family="Helvetica"
		font-size="35"
		letter-spacing="1"
		font-weight="600"
		font-style="italic"
		fill="gray"
	>
	{{userAndCardsData.cards.studentCard.endDate}}
	</text>
</svg>
