<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<app-header></app-header>
<ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>

<ion-content *ngIf="(rssFeedIsEmpty$ | async) === false">

  <ion-card *ngFor="let item of (rssFeed$ | async); let i = index">
    <div class="border-card safe-area-margin-lr">

      <app-rss-item-header [item]="item">
        <ng-template appRssItemHeaderButton>
          <ion-button (click)="openContent(i)" class="app-text-button-2 link-button" fill="clear">
            <ion-text class="app-text-5" *ngIf="!isContentVisible[i]">{{ "RSS.SEE_MORE" | translate }}</ion-text>
            <ion-text class="app-text-5" *ngIf="isContentVisible[i]">{{ "RSS.SEE_LESS" | translate }}</ion-text>
            <ion-icon class="app-icon-6" [class.content-open]="!isContentVisible[i]"
              [class.content-close]="isContentVisible[i]" name="arrow-right" aria-label=""></ion-icon>
          </ion-button>
        </ng-template>
      </app-rss-item-header>

    </div>

    <div class="rss-news-content safe-area-margin-lr" [ngClass]="{'visible': isContentVisible[i]}">
      <div class="border-content">

        <ion-row class="rss-news-description">
          <ion-text class="ion-text-wrap ion-margin">
            <div class="app-text-4" [innerHTML]="item.content"></div>
          </ion-text>
        </ion-row>

        <ion-row class="ion-justify-content-center">
          <ion-button class="open-link-button ion-no-margin" (click)="onClick(item)" expand="full">
            {{ "RSS.SEE_MORE_EXTERNAL" | translate }}
          </ion-button>
        </ion-row>
      </div>

    </div>
  </ion-card>
</ion-content>

<ion-content class="ion-padding" *ngIf="(rssFeedIsEmpty$ | async)">
  <ion-note> {{ "RSS.NO_DATA" | translate }} </ion-note>
</ion-content>
