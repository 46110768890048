<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-content>
  <app-widget [widgetId]="widgetIds.auth"></app-widget>

  <ion-list data-burger-id="menu-help" lines="none" class="menu-section">
    <app-widget [widgetId]="widgetIds.contactUs"></app-widget>
    <app-widget [widgetId]="widgetIds.staticPages"></app-widget>
  </ion-list>

  <ion-list lines="none" class="menu-section">
    <ion-item button="true" mode="md" detail="true" *ngFor="let menu of (dynamicMenuItems$ | async)" (click)="menuOpenerService.open(menu)">
      <app-custom-icon class="app-icon-4"
        slot="start"
        [icon]="menu.icon"
        [color]="(isDarkTheme$ | async) === true ? 'icon-primary': 'icon-primary-contrast'"
        [iconSourceSvgLightTheme]="menu.iconSourceSvgLightTheme"
        [iconSourceSvgDarkTheme]="menu.iconSourceSvgDarkTheme"></app-custom-icon>
      <ion-label class="app-text-3 light">{{menu.title}}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list lines="none" class="menu-section">
    <ion-item
      button="true"
      mode="md"
      detail="true"
      *ngFor="let menu of (staticMenuItems$ | async)"
      (click)="menuOpenerService.open(menu)"
      [attr.data-burger-id]="getMenuId(menu)"
    >
      <ion-icon slot="start" class="app-icon-4" name="{{menu.icon}}" aria-label=""></ion-icon>
      <ion-label class="app-text-3 light">{{menu.title | translate}}</ion-label>
    </ion-item>
  </ion-list>

  <app-widget  [widgetId]="widgetIds.socialNetwork"></app-widget>
</ion-content>

<ion-footer data-burger-id="menu-footer" class="ion-no-border">
<!--
  <ion-row class="ion-justify-content-center">
    <ion-button (click)="toggleDarkMode()"
                [attr.aria-label]="'MENU.THEME.CHANGE' | translate: {theme: ((isDarkTheme$ | async) === true ? 'MENU.THEME.DARK' : 'MENU.THEME.LIGHT') | translate}"
                class="app-round-button-1 ion-no-padding ion-no-margin"
                fill="clear" shape="round">
      <ion-icon *ngIf="(isDarkTheme$ | async) === false" aria-label="" name="moon" slot="icon-only"></ion-icon>
      <ion-icon *ngIf="(isDarkTheme$ | async) === true" aria-label="" name="sunny-outline" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-row>
  <ion-row class="ion-justify-content-center">
    <ion-button (click)="useLanguage(language)" *ngFor="let language of languages"
                [attr.aria-label]="'MENU.LANGUAGE.CHANGE' | translate: {language: ('MENU.LANGUAGE.' + language) | translate}"
                fill=“clear”>
      <ion-icon aria-label="" name="{{language}}"></ion-icon>
    </ion-button>
  </ion-row>
-->
  <ion-row
    *ngIf="appVersion$ | async as version"
    class="ion-justify-content-center ion-padding-bottom"
  >
    <ion-label class="app-title-6 light ion-no-padding">
      {{ 'MENU.VERSION' | translate }} {{ version }}
    </ion-label>
  </ion-row>
</ion-footer>
