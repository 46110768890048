<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<app-header>
  <ion-buttons slot="end">
    <ion-button (click)="presentPopover($event)" fill="clear" class="ion-no-padding" [attr.aria-label]="'MAP.FILTER' | translate">
      <ion-icon name="filter-outline" aria-label=""></ion-icon>
    </ion-button>

    <ion-button fill="clear" (click)="onLocateUserClick()" [attr.aria-label]="'MAP.LOCATE' | translate">
      <ion-icon slot="icon-only" name="locate" aria-label=""></ion-icon>
    </ion-button>
  </ion-buttons>

  <ion-popover #popover [isOpen]="isOpen" (didDismiss)="isOpen = false">
    <ng-template>
      <ion-list class="filter-list">
        <ion-row class="ion-justify-content-center">
          <ion-text class="app-title-5">{{ "MAP.FILTERS" | translate }}</ion-text>
        </ion-row>
        <form [formGroup]="form">
          <ion-item formArrayName="categoriesForm" *ngFor="let category of categoriesForm.controls; let i = index">
            <ion-label class="app-text-3 light ion-text-wrap">{{ getCategoryTranslation(categories[i]) | translate }}</ion-label>
            <ion-checkbox mode="md" [formControlName]="i"></ion-checkbox>
          </ion-item>
        </form>
      </ion-list>
    </ng-template>
  </ion-popover>
</app-header>
<ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>

<ion-list class="chip-list" *ngIf="categoriesSelected?.length > 0">
  <button *ngFor="let category of categoriesSelected; let i = index" (click)="removeSelectedCategory(category, i)"
          [attr.aria-label]="'MAP.REMOVE_FROM_FILTERS' | translate:{category: getCategoryTranslation(category) | translate}"
          class="chip-item">
    <ion-chip>
      {{ getCategoryTranslation(category) | translate }}
      <ion-icon name="close-circle-outline" aria-label=""></ion-icon>
    </ion-chip>
  </button>
</ion-list>

<ion-content scroll="false">
    <div class="map-page">
      <div id="map"></div>
    </div>
</ion-content>
