/*
 * Copyright ou © ou Copr. Université de Lorraine, (2022)
 *
 * Direction du Numérique de l'Université de Lorraine - SIED
 *  (dn-mobile-dev@univ-lorraine.fr)
 * JNESIS (contact@jnesis.com)
 *
 * Ce logiciel est un programme informatique servant à rendre accessible
 * sur mobile divers services universitaires aux étudiants et aux personnels
 * de l'université.
 *
 * Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 * et respectant les principes de diffusion des logiciels libres. Vous pouvez
 * utiliser, modifier et/ou redistribuer ce programme sous les conditions
 * de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 * sur le site "http://cecill.info".
 *
 * En contrepartie de l'accessibilité au code source et des droits de copie,
 * de modification et de redistribution accordés par cette licence, il n'est
 * offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 * seule une responsabilité restreinte pèse sur l'auteur du programme, le
 * titulaire des droits patrimoniaux et les concédants successifs.
 *
 * À cet égard, l'attention de l'utilisateur est attirée sur les risques
 * associés au chargement, à l'utilisation, à la modification et/ou au
 * développement et à la reproduction du logiciel par l'utilisateur étant
 * donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 * manipuler et qui le réserve donc à des développeurs et des professionnels
 * avertis possédant des connaissances informatiques approfondies. Les
 * utilisateurs sont donc invités à charger et à tester l'adéquation du
 * logiciel à leurs besoins dans des conditions permettant d'assurer la
 * sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 * à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 * Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 * pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 * termes.
 */

import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticatedUser, authenticatedUser$, AuthorizationHelper } from '@multi/shared';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ScheduleModuleConfig, SCHEDULE_CONFIG } from '../../schedule.config';
import { impersonatedScheduleStoreManager } from '../../schedule.repository';
import { ScheduleService } from '../../schedule.service';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['../../../../../../src/theme/app-theme/styles/schedule/select-user.component.scss'],
})
export class SelectUserComponent {

  public form: FormGroup;
  public isSelectUserModalOpen = false;
  public isAuthorizedUser$: Observable<boolean>;

  constructor(@Inject(SCHEDULE_CONFIG) private config: ScheduleModuleConfig, private scheduleService: ScheduleService) {
    this.isAuthorizedUser$ = authenticatedUser$.pipe(
      filter((authenticatedUser: AuthenticatedUser) => !!authenticatedUser),
      take(1),
      map((authenticatedUser: AuthenticatedUser) => {
        const authorizationHelper = new AuthorizationHelper(authenticatedUser.roles);
        return authorizationHelper.filter([
          {
            authorization: {
              roles: this.config.managerRoles || [],
              type: 'ALLOW'
            }
          }
        ]).length > 0;
      })
    );

    this.form = new FormGroup({
      login: new FormControl(null, Validators.required),
    });
  }

  openSelectUserModal() {
    this.form.reset();
    this.isSelectUserModalOpen = true;
  }

  onDismissSelectUserModal() {
    this.isSelectUserModalOpen = false;
  }

   onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const login = this.form.value.login;
    this.isSelectUserModalOpen = false;
    impersonatedScheduleStoreManager.resetStore();
    this.scheduleService.setAsUser(login);
    this.scheduleService.loadScheduleToState().pipe(take(1)).subscribe();
  }
}
