/*
 * Copyright ou © ou Copr. Université de Lorraine, (2022)
 *
 * Direction du Numérique de l'Université de Lorraine - SIED
 *  (dn-mobile-dev@univ-lorraine.fr)
 * JNESIS (contact@jnesis.com)
 *
 * Ce logiciel est un programme informatique servant à rendre accessible
 * sur mobile divers services universitaires aux étudiants et aux personnels
 * de l'université.
 *
 * Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 * et respectant les principes de diffusion des logiciels libres. Vous pouvez
 * utiliser, modifier et/ou redistribuer ce programme sous les conditions
 * de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 * sur le site "http://cecill.info".
 *
 * En contrepartie de l'accessibilité au code source et des droits de copie,
 * de modification et de redistribution accordés par cette licence, il n'est
 * offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 * seule une responsabilité restreinte pèse sur l'auteur du programme, le
 * titulaire des droits patrimoniaux et les concédants successifs.
 *
 * À cet égard, l'attention de l'utilisateur est attirée sur les risques
 * associés au chargement, à l'utilisation, à la modification et/ou au
 * développement et à la reproduction du logiciel par l'utilisateur étant
 * donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 * manipuler et qui le réserve donc à des développeurs et des professionnels
 * avertis possédant des connaissances informatiques approfondies. Les
 * utilisateurs sont donc invités à charger et à tester l'adéquation du
 * logiciel à leurs besoins dans des conditions permettant d'assurer la
 * sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 * à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 * Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 * pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 * termes.
 */

import { Injectable } from '@angular/core';
import { add, eachDayOfInterval, Interval, isAfter } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Event, Schedule } from '../schedule.repository';
import { formatDay } from '../schedule.service';
import { ScheduleService } from '../schedule.service';

export interface EventsByDay {
  day: string;
  events: Event[];
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleListService {

  public showEventEvt: Subject<void> = new Subject();

  constructor(private scheduleService: ScheduleService) { }

  eventsToEventsByDay(events: Event[], dateInterval: Interval): EventsByDay[] {

    const days = eachDayOfInterval(dateInterval);

    const eventsMapByDay = events
      .reduce((accumulatorEventsMapByDay, event) => {
        const day = formatDay(new Date(event.startDateTime));

        if (!accumulatorEventsMapByDay.has(day)) {
          accumulatorEventsMapByDay.set(day, []);
        }

        accumulatorEventsMapByDay.get(day).push(event);

        return accumulatorEventsMapByDay;
      }, new Map<string, Event[]>());

    return days
      .map(date => formatDay(date))
      .map(day => ({
        day,
        events: eventsMapByDay.has(day) ?
          eventsMapByDay.get(day) :
          []
      })
      );
  }

  loadEventsByDays(viewStartDate, endDateToLoad, outOfStateSchedule?: Schedule): Observable<EventsByDay[]> {

    const intervalViewForStateEvents = { start: viewStartDate, end: this.scheduleService.getStateEndDate() };

    if (isAfter(endDateToLoad, this.scheduleService.getStateEndDate())) {

      const nextDateAfterStateEndDate = add(this.scheduleService.getStateEndDate(), { days: 1 });
      const outOfStateInterval = { start: nextDateAfterStateEndDate, end: endDateToLoad };

      return this.scheduleService.getStoreManager().displayedEvents$.pipe(
        map(events => this.eventsToEventsByDay(events, intervalViewForStateEvents)),
        switchMap(stateEventsByDay => this.scheduleService.outOfStateScheduleToDisplayedEvents(outOfStateSchedule).pipe(
            map(events => {
              const outOfStateEventsByDay = this.eventsToEventsByDay(events, outOfStateInterval);

              return [...stateEventsByDay, ...outOfStateEventsByDay];
            }
            )
          )
        )
      );

    } else {

      return this.scheduleService.getStoreManager().displayedEvents$.pipe(
        map(events => {
          const dateInterval = { start: viewStartDate, end: endDateToLoad };

          return this.eventsToEventsByDay(events, dateInterval);
        }));

    }
  }

  emitShowCourseEvt() {
    this.showEventEvt.next();
  }
}
