/*
 * Copyright ou © ou Copr. Université de Lorraine, (2022)
 *
 * Direction du Numérique de l'Université de Lorraine - SIED
 *  (dn-mobile-dev@univ-lorraine.fr)
 * JNESIS (contact@jnesis.com)
 *
 * Ce logiciel est un programme informatique servant à rendre accessible
 * sur mobile divers services universitaires aux étudiants et aux personnels
 * de l'université.
 *
 * Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 * et respectant les principes de diffusion des logiciels libres. Vous pouvez
 * utiliser, modifier et/ou redistribuer ce programme sous les conditions
 * de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 * sur le site "http://cecill.info".
 *
 * En contrepartie de l'accessibilité au code source et des droits de copie,
 * de modification et de redistribution accordés par cette licence, il n'est
 * offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 * seule une responsabilité restreinte pèse sur l'auteur du programme, le
 * titulaire des droits patrimoniaux et les concédants successifs.
 *
 * À cet égard, l'attention de l'utilisateur est attirée sur les risques
 * associés au chargement, à l'utilisation, à la modification et/ou au
 * développement et à la reproduction du logiciel par l'utilisateur étant
 * donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 * manipuler et qui le réserve donc à des développeurs et des professionnels
 * avertis possédant des connaissances informatiques approfondies. Les
 * utilisateurs sont donc invités à charger et à tester l'adéquation du
 * logiciel à leurs besoins dans des conditions permettant d'assurer la
 * sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 * à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 * Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 * pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 * termes.
 */

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take } from 'rxjs/operators';
import { getAuthToken } from '../auth/auth.repository';
import { KeepAuthService } from '../auth/keep-auth.service';
import { SsoExternalLinkQueryDto, SsoServiceTokenQueryDto } from './sso.dto';

@Injectable({
    providedIn: 'root'
})
export class SsoService {

    constructor(
        @Inject('environment')
        private environment: any,
        private http: HttpClient,
        private keepAuthService: KeepAuthService,
    ) {}

    public getSsoExternalLink(query: SsoExternalLinkQueryDto): Observable<string> {
        return getAuthToken().pipe(
            take(1),
            filter(authToken => authToken != null),
            switchMap(authToken => this.requestSsoServiceToken({
                service: query.service,
                authToken,
            })),
            map(ssoServiceToken => query.urlTemplate.replace('{st}', ssoServiceToken))
        );
    }

    private requestSsoServiceToken(query: SsoServiceTokenQueryDto): Observable<string> {
        const url = `${this.environment.apiEndpoint}/sso-service-token`;

        return this.http.post<string>(url, query, {responseType: 'text' as 'json'}).pipe(
          catchError(err => {
            // do not handle non HTTP errors
            if (!(err instanceof HttpErrorResponse)) {
              return throwError(err);
            }

            // do not handle non 401 HTTP errors
            if (err.status !== 401) {
              return throwError(err);
            }

            // in case of error 401 user authentication is expired, we try to reauthenticate
            // (available if user has allowed saving his credentials)
            return this.keepAuthService.reauthenticateIfAvailable().pipe(
              concatMap(authenticatedUser => authenticatedUser ?
                this.http.post<string>(url, {...query, authToken: authenticatedUser.authToken}, {responseType: 'text' as 'json'}) :
                throwError(err)
              )
            );
        }));
    }
}
