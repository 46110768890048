<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button></app-back-button>
    </ion-buttons>
    <ion-title class="app-centered-header-title app-title-4">
      {{ "AUTH.MENU" | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>

<ion-content class="ion-padding" *ngIf="translatedPageContent$ | async as page">
  <form [formGroup]="loginForm" (ngSubmit)="submit()">
    <ion-row class="ion-justify-content-center ion-text-center">
      <div class="app-text-3" [innerHTML]="page.connexion_text"></div>
    </ion-row>

    <ion-item lines="full">
      <ion-label position="floating" class="input-label app-text-3 light">{{ 'AUTH.LOGIN.USERNAME' | translate }}</ion-label>
      <ion-input (keyup.enter)="gotoField(passwordField)" type="text" formControlName="username" class="form-control" id="username" required></ion-input>
      <ion-note class="app-text-5 danger" slot="error" *ngIf="username && username.errors && (username.dirty && username.touched)">
        {{ 'AUTH.LOGIN.REQUIRED.USERNAME' | translate }}
      </ion-note>
    </ion-item>

    <ion-item lines="full">
      <ion-label position="floating" class="input-label app-text-3 light">{{ 'AUTH.LOGIN.PASSWORD' | translate }}</ion-label>
      <ion-input #passwordField (keyup.enter)="keyboardSubmit()" class="form-control" formControlName="password" id="password"
                 required type="password"></ion-input>
      <ion-note class="app-text-5 danger" slot="error" *ngIf="password && password.errors && (password.dirty && password.touched)">
        {{ 'AUTH.LOGIN.REQUIRED.PASSWORD' | translate }}
      </ion-note>
    </ion-item>

    <ion-item lines="none">
      <ion-checkbox
        [checked]="(saveCredentialsOnAuthentication$ | async)"
        (ionChange)="onSaveCredentialsOnAuthenticationChange($event)"
        class="ion-margin-end"
        mode="md"
      >
      </ion-checkbox>
      <ion-label class="app-text-3 light ion-text-wrap">{{ 'AUTH.LOGIN.SAVE_CREDENTIALS' | translate }}</ion-label>
    </ion-item>

    <ion-row class="ion-margin-top ion-padding-horizontal ion-justify-content-center">
      <ion-button class="app-rectangle-button-1" type="submit" lines="none" [disabled]="!loginForm.valid || isLoading">
        <ion-label>{{ 'AUTH.LOGIN.SUBMIT' | translate }}</ion-label>
      </ion-button>
    </ion-row>
  </form>
</ion-content>
