<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>
<app-header></app-header>

<ion-content *ngIf="(userAndCardsData$ | async) as userCards">
  <ion-content *ngIf="userCards.cards && (!userCards.errors || userCards.errors.length === 0)">
    <ion-content *ngIf="!userCards.cards.staffCard">
      <ion-card class="card safe-area-lr-ionic-margin" *ngFor="let card of userCards.cards | keyvalue" (click)="openModal(card.key)">
        <ion-card-header>
          <ion-card-subtitle> </ion-card-subtitle>
          <ion-card-title class="app-title-5">
            {{card.value.title}}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <app-student-card *ngIf="card.key === 'studentCard'" [userAndCardsData]="userCards"></app-student-card>
          <app-eu-student-card *ngIf="card.key === 'euStudentCard'" [userAndCardsData]="userCards"></app-eu-student-card>
        </ion-card-content>
      </ion-card>

      <app-card-modal [userAndCardsData]="userCards"></app-card-modal>
    </ion-content>

    <ion-content *ngIf="userCards.cards.staffCard">
      <ion-card class="safe-area-lr-ionic-margin">
        <ion-card-content>
          <app-staff-card [userAndCardsData]="userCards"></app-staff-card>
        </ion-card-content>
      </ion-card>
      <ion-row class="ion-justify-content-center">
      <qrcode *ngIf="userCards.cards.staffCard.qrCode" [qrdata]="userCards.cards.staffCard.qrCode.value" [errorCorrectionLevel]="'M'"
        [width]="400"></qrcode>
      </ion-row>
    </ion-content>
  </ion-content>

  <ion-content *ngIf="userCards.errors && userCards.errors.length > 0 && hasKnownError(userCards.errors); else unknowError">
    <ion-content class="ion-padding">
      <ng-container *ngFor="let error of userCards.errors">
        <ion-note *ngIf="isKnownError(error)" class="column app-text-2" >
          {{ (userCards.affiliation === 'staff' ? 'CARDS.ERRORS_STAFF.' : 'CARDS.ERRORS_STUDENT.') + error | translate}}
        </ion-note>
      </ng-container>
    </ion-content>
  </ion-content>
</ion-content>

<ion-content class="ion-padding" *ngIf="(userAndCardsData$ | async) === null && !isLoading">
  <ion-note> {{ "CARDS.ERRORS.NO_DATA" | translate }} </ion-note>
</ion-content>

<ng-template #unknowError>
  <ion-content class="ion-padding">
    <ion-note class="column app-text-2"> {{ 'CARDS.ERRORS.UNKNOWN' | translate}}</ion-note>
  </ion-content>
</ng-template>
