<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<div #ionContent class="div-content">
  <ion-content class="zoomable-content">

    <ion-item lines="none" *ngIf="loadScheduleOutOfStateError === true">
      <ion-note *ngIf="errorIsBefore" class="ion-padding app-text-4 danger"> {{ "SCHEDULE.REQUEST_ERROR.BEFORE" |
        translate }} {{dateError |
        completeLocalDate}}.</ion-note>
      <ion-note *ngIf="!errorIsBefore" class="ion-padding app-text-4 danger"> {{ "SCHEDULE.REQUEST_ERROR.AFTER" |
        translate }} {{dateError |
        completeLocalDate}}.</ion-note>
    </ion-item>

    <ion-range *ngIf="viewType !== 'day'" [(ngModel)]="zoomLevel" (ionChange)="updateZoomLevel()" min="0" max="2" step="1" pin="true" snaps="true">
      <ion-label class="app-text-6" slot="start">0</ion-label>
      <ion-label class="app-text-6" slot="end">x2</ion-label>
      <ion-label class="app-text-6" slot="tick" value="1">1</ion-label>
    </ion-range>
    <full-calendar #calendar [options]="calendarOptions">
      <ng-template #eventContent let-arg>
        <app-calendar-event [event]="arg.event.extendedProps.event" [viewType]="viewType"></app-calendar-event>
      </ng-template>
    </full-calendar>

    <ion-modal #modal [isOpen]="isEventDetailOpen" [initialBreakpoint]="0.6" [breakpoints]="[0, 0.6, 1]"
      (willDismiss)="dismissModal()">
      <ng-template>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="end">
              <ion-button (click)="dismissModal()"><ion-text class="app-title-5">{{ 'SCHEDULE.CALENDAR.CLOSE' |
                  translate }}</ion-text></ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <app-event-detail *ngIf="selectedEvent" [event]="selectedEvent"></app-event-detail>
        </ion-content>
      </ng-template>
    </ion-modal>

  </ion-content>
</div>
