<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-content *ngIf="(storeManager.schedule$ | async) as schedule" #scrollContent>

  <ng-container *ngIf="(areEventsFromActivePlannings$ | async) === true">

    <ion-item-group *ngFor="let eventsByDay of (eventsByDays$ | async);">

      <ion-item-divider sticky class="app-text-2 ion-text-center complete-date" [id]="eventsByDay.day">
        <ion-col>{{eventsByDay.day | completeLocalDate}}</ion-col>
      </ion-item-divider>

      <ion-col class="app-text-4 ion-text-center safe-area-padding-lr" *ngIf="!eventsByDay.events.length">
        <p>{{ "SCHEDULE.NO_EVENTS_THIS_DAY" | translate }}</p>
      </ion-col>

      <app-event-detail *ngFor="let event of eventsByDay.events" [event]="event"
        (hideEventEvt)="keepScrollPosition()"></app-event-detail>

    </ion-item-group>

    <ion-row class="ion-justify-content-center ion-margin-bottom">
      <ion-button class="app-text-4 see-more" fill="outline" (click)="loadMoreEvents()">
        <ion-icon name="add-outline" aria-label=""></ion-icon>
        {{ "SCHEDULE.LIST.SEE_MORE" | translate }}
      </ion-button>
    </ion-row>

  </ng-container>

</ion-content>

<ion-content class="ion-padding" *ngIf="(areEventsFromActivePlannings$ | async) === false">
  <ion-note class="app-text-4 safe-area-padding-lr"> {{ "SCHEDULE.NO_EVENTS" | translate }} </ion-note>
</ion-content>

<ion-content class="ion-padding" *ngIf="(storeManager.schedule$ | async) === null">
  <ion-note class="app-text-4 safe-area-padding-lr"> {{ "SCHEDULE.NO_DATA" | translate }} </ion-note>
</ion-content>
