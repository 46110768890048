<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>

<ng-container *ngIf="translatedPageContent$ | async as page">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <app-back-button></app-back-button>
      </ion-buttons>

      <ion-title class="app-centered-header-title app-title-4">{{ page.title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div [innerHTML]="page.content" class="safe-area-padding-lr"></div>

    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
      <ion-list>
        <ion-item>
          <ion-label class="input-label" position="floating">{{ "CONTACT-US.YOUR_EMAIL" | translate }}</ion-label>
          <ion-input formControlName="from"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label class="input-label" position="floating">{{ "CONTACT-US.SUBJECT" | translate }}</ion-label>
          <ion-input formControlName="subject"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label class="input-label" position="floating">{{ "CONTACT-US.MESSAGE" | translate }}</ion-label>
          <ion-textarea [autoGrow]="true" formControlName="text"></ion-textarea>
        </ion-item>
      </ion-list>

      <ion-toolbar class="contact-form-toolbar">
        <ion-buttons slot="end">
          <ion-button class="app-text-button-1" [disabled]="!contactForm.valid" type="submit">
            <ion-text class="app-text-4"> {{ "CONTACT-US.SEND" | translate }} </ion-text>
            <ion-icon class="app-icon-6" name="arrow-right" aria-label=""></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </form>
  </ion-content>
</ng-container>
