<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-card>
  <ion-card-header>
    <ion-card-title class="app-text-2 ion-text-center">
      {{displayShortenedDate ? (event.startDateTime | shortenedDate) : ""}} {{event.startDateTime | localHour}} -
      {{event.endDateTime | localHour}}
    </ion-card-title>
    <ion-button fill="clear" size="small" class="ion-text-center hide-event-icon" (click)="hideAllSimilarCourse(event.course)"
                *ngIf="showHideButton" [disabled]="disableHideCourseButton" [attr.aria-label]="'SCHEDULE.HIDE_SIMILAR' | translate">
      <ion-icon name="eye-off" aria-label=""></ion-icon>
    </ion-button>
  </ion-card-header>

  <ion-card-content>
      <ion-row>
        <ion-col size="11.5">
          <ion-row class="card-labels course-label">
            <ion-text class="app-text-3">{{event.course.label}}</ion-text>
          </ion-row>
          <ion-row class="card-labels" *ngFor="let room of event.rooms">
            <ion-icon class="app-icon-5"  name="business-outline" aria-label=""></ion-icon>
            <ion-text class="app-text-4 light">{{room.label}} - {{room.building}}</ion-text>
          </ion-row>
          <ion-row class="card-labels" *ngFor="let teacher of event.teachers">
            <ion-icon class="app-icon-5" name="man-outline" aria-label=""></ion-icon>
            <ion-text class="app-text-4 light">{{teacher.displayname}}</ion-text>
          </ion-row>
          <ion-row class="card-labels" *ngIf="event.course.url && isCourseUrlValid()" (click)="openCourseURL(event.course.url)">
            <ion-icon class="app-icon-5"  name="link-outline" aria-label=""></ion-icon>
            <ion-text class="app-text-4 light">{{ "SCHEDULE.COURSE_URL_TEXT" | translate }}</ion-text>
          </ion-row>
          <ng-container *ngIf="isGroupsVisible"> <!-- Groups -->
            <ion-row class="card-labels" *ngFor="let group of event.groups">
              <ion-icon class="app-icon-5" name="people-circle-outline" aria-label=""></ion-icon>
              <ion-text class="app-text-4 light">{{group.label}}</ion-text>
            </ion-row>
          </ng-container>

          <ion-row *ngIf="event.groups.length > 0" class="ion-justify-content-end">
            <ion-button class="app-text-button-2" fill="clear" (click)="toggleExpandGroups()">
              <ion-text class="app-text-4" *ngIf="!isGroupsVisible" >{{ "SCHEDULE.SEE_GROUPS" | translate }}</ion-text>
              <ion-text class="app-text-4" *ngIf="isGroupsVisible" >{{ "SCHEDULE.HIDE_GROUPS" | translate }}</ion-text>
              <ion-icon class="app-icon-6" [class.content-open]="!isGroupsVisible"
                        [class.content-close]="isGroupsVisible" name="arrow-right" aria-label=""></ion-icon>
            </ion-button>
          </ion-row>
        </ion-col>
        <ion-col
          [ngStyle]="{'background-color': event.course.color}"
          size="auto"
          class="course-type"
        ></ion-col>
      </ion-row>
  </ion-card-content>
</ion-card>
