<!--
  ~ Copyright ou © ou Copr. Université de Lorraine, (2022)
  ~
  ~ Direction du Numérique de l'Université de Lorraine - SIED
  ~  (dn-mobile-dev@univ-lorraine.fr)
  ~ JNESIS (contact@jnesis.com)
  ~
  ~ Ce logiciel est un programme informatique servant à rendre accessible
  ~ sur mobile divers services universitaires aux étudiants et aux personnels
  ~ de l'université.
  ~
  ~ Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
  ~ et respectant les principes de diffusion des logiciels libres. Vous pouvez
  ~ utiliser, modifier et/ou redistribuer ce programme sous les conditions
  ~ de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
  ~ sur le site "http://cecill.info".
  ~
  ~ En contrepartie de l'accessibilité au code source et des droits de copie,
  ~ de modification et de redistribution accordés par cette licence, il n'est
  ~ offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
  ~ seule une responsabilité restreinte pèse sur l'auteur du programme, le
  ~ titulaire des droits patrimoniaux et les concédants successifs.
  ~
  ~ À cet égard, l'attention de l'utilisateur est attirée sur les risques
  ~ associés au chargement, à l'utilisation, à la modification et/ou au
  ~ développement et à la reproduction du logiciel par l'utilisateur étant
  ~ donné sa spécificité de logiciel libre, qui peut le rendre complexe à
  ~ manipuler et qui le réserve donc à des développeurs et des professionnels
  ~ avertis possédant des connaissances informatiques approfondies. Les
  ~ utilisateurs sont donc invités à charger et à tester l'adéquation du
  ~ logiciel à leurs besoins dans des conditions permettant d'assurer la
  ~ sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
  ~ à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
  ~
  ~ Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
  ~ pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
  ~ termes.
  -->

<ion-buttons>
  <ion-button *ngIf="isAuthorizedUser$ | async" (click)="openSelectUserModal()" fill="clear" class="ion-no-padding"
              [attr.aria-label]="'SCHEDULE.MANAGEMENT.TITLE' | translate">
    <ion-icon slot="icon-only" name="people-circle-outline" aria-label=""></ion-icon>
  </ion-button>
</ion-buttons>

<ion-modal *ngIf="isAuthorizedUser$ | async" [isOpen]="isSelectUserModalOpen" [initialBreakpoint]="0.35" [breakpoints]="[0, 0.35]" (willDismiss)="onDismissSelectUserModal()">
  <ng-template>
    <ion-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <ion-item>
          <ion-label class="app-text-4" position="floating">{{ 'SCHEDULE.MANAGEMENT.USERNAME_FORM_LABEL' | translate }}</ion-label>
          <ion-input formControlName="login"></ion-input>
        </ion-item>
        <ion-button expand="full" type="submit" [disabled]="!form.valid"><ion-text class="app-text-3 contrast">{{ 'SCHEDULE.MANAGEMENT.VALIDATE_FORM_BUTTON' | translate }}</ion-text></ion-button>
      </form>
    </ion-content>
  </ng-template>
</ion-modal>
